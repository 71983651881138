<template>
  <q-menu anchor="top right" self="top left">
    <template v-if="availableLabels.length || labelFilter">
      <q-input v-model="labelFilter" class="q-pa-sm" :label="$t('filter_or_new_label')" dense :rules="[ val => !val || isLabelValid(val) || $t('label.validation')]">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-separator />
      <q-scroll-area class="labelList">
        <q-list dense>
          <q-item v-for="label in availableLabels" :key="label.name">
            <q-item-section side>
              <q-checkbox v-model="localChanges" :val="label.name" :color="label.colour" />
            </q-item-section>
            <q-item-section>
              {{ label.name }}
            </q-item-section>
            <q-tooltip v-if="label.description">
              {{ label.description }}
            </q-tooltip>
          </q-item>
        </q-list>
      </q-scroll-area>
    </template>
    <div v-else class="q-pa-sm">
      <m-empty-state padding-top="0" :font-size="26" icon="label" :label="$t('no_existing_labels')" class="q-ma-lg" />
    </div>
    <q-separator />
    <q-list v-if="showApply">
      <q-item v-if="localChanges.length ? hasPermission('can.replace.labels') : hasPermission('can.detach.labels')" v-close-popup clickable @click="applyLabelChanges">
        <q-item-section>
          {{ $t('apply') }}
        </q-item-section>
      </q-item>
      <q-item v-else>
        <q-item-section class="text-faded">
          {{ $t('error.permission.make_changes') }}
        </q-item-section>
      </q-item>
    </q-list>
    <q-list v-if="hasPermission('can.create.labels') && createNew">
      <q-item clickable @click="createNewLabel">
        <q-item-section>
          <div>
            <span class="text-bold">
              "{{ labelFilter }}"
            </span>
            ({{ $t('create_new') }})
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <q-list v-else-if="hasPermission('can.manage.labels')">
      <q-item v-if="availableLabels.length" v-close-popup clickable @click="$router.push({ hash: '/organisation/labels' })">
        <q-item-section>
          {{ $t('manage_labels') }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>
<script>
import { isEqual, debounce, cloneDeep } from 'lodash'
import authentication from 'mixins/authentication'
import labelUtil from 'utils/label-utils'
import { MEmptyState } from 'components/'

export default {
  components: { MEmptyState },
  mixins: [authentication],
  props: {
    resource: {
      type: String,
      required: true
    },
    resourceReference: {
      type: String,
      required: true
    },
    selectedLabels: Array,
    labels: Array
  },
  data () {
    return {
      labelFilter: null,
      localChanges: [],
      newLabels: [],
      text: 'some text'
    }
  },
  computed: {
    availableLabels () {
      var labels = this.labels.filter((label) => {
        return this.labelFilter ? label.name.toLowerCase().includes(this.labelFilter.toLowerCase()) : true
      })
      var selected = []
      var notSelected = []
      labels.forEach(label => {
        if (this.selectedLabels.includes(label.name)) selected.push(label)
        else notSelected.push(label)
      })
      return selected.concat(this.newLabels.concat(notSelected))
    },
    createNew () {
      return this.labelFilter && this.availableLabels.every(label => label.name.toLowerCase() !== this.labelFilter.toLowerCase()) && this.isLabelValid(this.labelFilter)
    },
    showApply () {
      return !isEqual(this.localChanges, this.selectedLabels)
    }
  },
  created () {
    this.localChanges = cloneDeep(this.selectedLabels)
  },
  methods: {
    isLabelValid: val => labelUtil.isLabelValid(val),
    mapLabelsRequest (labels) {
      return {
        resource: this.resource,
        reference: this.resourceReference,
        labels: labels
      }
    },
    applyLabelChanges: debounce(function () {
      if (this.localChanges.length) this.submitChanges()
      else this.detachAllLabels()
    }),
    async submitChanges () {
      try {
        const message = this.$t('label.attached')
        await this.$store.dispatch('entities/labels/attachLabels', this.mapLabelsRequest(this.localChanges))
        this.$q.notify({
          message,
          color: 'positive',
          icon: 'done_all'
        })
      } catch (error) {
        const message = this.$t('error.custom', { error: error?.data?.message })
        this.$q.notify({
          message,
          color: 'negative',
          icon: 'clear'
        })
      }
    },
    async detachAllLabels () {
      try {
        const message = this.$t('label.detached')
        await this.$store.dispatch('entities/labels/detachLabels', this.mapLabelsRequest(this.selectedLabels))
        this.$q.notify({
          message,
          color: 'positive',
          icon: 'done_all'
        })
      } catch (error) {
        const message = this.$t('error.custom', { error: error?.data?.message })
        this.$q.notify({
          message,
          color: 'negative',
          icon: 'clear'
        })
      }
    },
    createNewLabel () {
      if (this.createNew) {
        const newLabel = labelUtil.convertLabelToKebabCase(this.labelFilter)
        this.labelFilter = null
        const labelAlreadyExists = this.availableLabels.some((label) => {
          return label.name === newLabel
        })
        if (!labelAlreadyExists) {
          this.newLabels.push({
            name: newLabel,
            new: true
          })
        }
        this.localChanges.push(newLabel)
      } else {
        this.$router.push({ hash: '/organisation/labels/form',
          query: {
            resource: this.resource,
            reference: this.resourceReference
          } })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.labelList
  height 200px
  max-height 400px
  width 300px
</style>
